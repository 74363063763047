<template>
  <v-app>
    <v-container class="px-10">
      <v-row class="mt-5" no-gutters>
        <v-col>
          <h5>Tiêu đề ghi chú</h5>
          <ValidationObserver v-if="isEdited" v-slot="{ handleSubmit }">
            <v-form
              @submit.prevent="handleSubmit(handleUpload)"
              method="post"
              id="update-title-form"
            >
              <ValidationProvider
                name="title"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="exam.title"
                  placeholder="Nhập từ khóa"
                  outlined
                  dense
                ></v-text-field>
                <span class="validate-error text-danger text-danger">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>

              <v-row class="justify-center d-flex">
                <v-btn
                  type="submit"
                  depressed
                  form="update-title-form"
                  class="white--text"
                  color="#0e0e93"
                  >Cập Nhật</v-btn
                >
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-col>
        <v-col class="justify-end text-end">
          <v-icon @click="editTitle" class="mr-2">mdi-pencil</v-icon>
        </v-col>
      </v-row>
      <v-row v-if="!isEdited">
        <v-col>
          <p>{{ exam.title }}</p>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col class="justify-center text-center">
          <v-btn
            rounded
            color="primary"
            @click="gotoExamAdding"
            >Thêm câu hỏi mới</v-btn
          >
        </v-col>
      </v-row>
      <div v-for="item in questions" :key="item.id">
        <div>{{item.title}}</div>
        <div v-for="question in item.questions" :key="question.id">
          <div>{{question.content}}</div>
        </div>
      </div>
      <v-row v-for="(groupQuestion, index) in groupQuestions" :key="index">
        <v-col cols="12">
          <v-row
            ><v-col cols="6">
              <div class="h1">
                Nhóm câu hỏi:<span v-html="groupQuestion.title"></span></div
            ></v-col>
            <v-col class="text-end" cols="6"
              ><v-icon @click="editGroupQuestion(groupQuestion.id)"
                >mdi-pencil</v-icon
              >
              <v-icon
                @click="removeGroupQuestion(groupQuestion.id)"
                class="mr-2"
                >mdi-delete</v-icon
              >
            </v-col>
          </v-row>
          <v-row v-if="groupQuestion.type==1">
            <v-col>
              <div v-if="groupQuestion.audio">
                <vuetify-audio
                  :file="groupQuestion.audio.src"
                  color="success"
                  downloadable
                ></vuetify-audio>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row v-for="question in groupQuestion.questions" :key="question.id">
            <v-col cols="12">
              <div class="headline">Câu hỏi:</div>
              <span v-html="question.content"></span>
            </v-col>
            <v-col cols="12">
              <h6>Đáp án</h6>
              <ul class="pl-10" id="example-1">
                <li v-for="answer in question.answers" :key="answer.id">
                  <span v-if="answer.is_correct == true" class="green--text">{{
                    answer.content
                  }}</span>
                  <span v-else>{{ answer.content }}</span>
                </li>
              </ul></v-col
            >
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import { mapGetters } from "vuex";
import { GET_LESSON_DETAIL } from "@/store/lessons.module";
// import { GET_EXAM_DETAIL,,GET_GROUP_QUESTION_LIST,, } from "@/store/exams.module";
import {
  
  GET_QUESTION_LIST,UPDATE_EXAM_TITLE, DELETE_GROUP_QUESTION_DETAIL
} from "@/store/exams.module";
export default {
  components: {
    VuetifyAudio: () => import("vuetify-audio"),
  },
  async created() {
    await this.$store.dispatch(GET_LESSON_DETAIL, {
      lesson_slug: this.$route.query.lesson_slug,
    });
    // await this.$store.dispatch(GET_GROUP_QUESTION_LIST, {
    //   lesson_id: this.lesson.id,
    // });
      //  await this.$store.dispatch(GET_EXAM_DETAIL, {
      //   lesson_slug: this.$route.query.lesson_slug,
      // });
    await this.$store.dispatch(GET_QUESTION_LIST, {
      exam_id:1 ,
    });
  },
  data: () => ({
    isOpenAddBox: false,
    title: "Bai kiem tra so 5",
    isEdited: false,
  }),
  computed: {
    // ...mapState({
    //   questions: (state) => state.questions.questions,
    //   lesson: (state) => state.lessons.lessonDetail,
    // }),
    ...mapGetters({
      groupQuestions: "groupQuestionList",
      exam:"examDetail",
      questions :"examQuestionList"
    }),
    getQuestions() {
      return this.questions;
    },
  },
  methods: {
    editTitle() {
      this.isEdited = true;
    },
    gotoExamAdding() {
      this.$router.push({
        name: "exam-group-adding",
        query: { lesson_slug: this.lesson.slug },
      });
    },
    cancel() {
      this.isOpenAddBox = false;
    },
    handleUpload() {
      this.$store
        .dispatch(UPDATE_EXAM_TITLE, {
          exam: this.exam,
        })
        .then(() => {
          this.isEdited = false;
        });
    },
    editGroupQuestion(group_question_id) {
      this.$router.push({
        name: "exam-group-adding",
        query: {
          lesson_slug: this.lesson.slug,
          group_question_id: group_question_id,
        },
      });
    },

    removeGroupQuestion(id) {
      confirm("Are you sure you want to delete this item?") &&
        this.$store
          .dispatch(DELETE_GROUP_QUESTION_DETAIL, {
            group_question_id: id,
          })
          // .then(() => {
          //   this.$store.dispatch(GET_GROUP_QUESTION_LIST, {
          //     lesson_id: this.lesson.id,
          //   });
          // });
    },
  },
};
</script>

<style>
</style>
